export default class {
    constructor(){
        this.lang = "en";
        this.texts = {
            "Add new case":{
                "fi":"Lisää uusi tapaus",
                "en":"Add new case"
            },
            "Add new person":{
                "fi":"Lisää uusi henkilö",
                "en":"Add new person"
            },
            "Add person":{
                "fi":"Lisää henkilö",
                "en":"Add person"
            },
            "Cases":{
                "fi":"Tapaukset",
                "en":"Cases"
            },
            "Persons":{
                "fi":"Henkilöt",
                "en":"Persons"
            },
            "Info":{
                "fi":"Lisätietoa",
                "en":"Info"
            },
            "Sign out":{
                "fi":"Kirjaudu ulos",
                "en":"Sign out"
            },
            "Open":{
                "fi":"Avaa",
                "en":"Open"
            },
            "New case":{
                "fi":"Uusi tapaus",
                "en":"New case"
            },
            "Create a new case":{
                "fi":"Luo uusi tapaus",
                "en":"Create a new case"
            },
            "Short description":{
                "fi":"Lyhyt kuvaus",
                "en":"Short description"
            },
            "Description":{
                "fi":"Kuvaus",
                "en":"Description"
            },
            "Temperature":{
                "fi":"Lämpötila",
                "en":"Temperature"
            },
            "Humidity":{
                "fi":"Ilmankosteus",
                "en":"Humidity"
            },
            "Clothing":{
                "fi":"Vaatetus",
                "en":"Clothing"
            },
            "Convective Heat Coefficient":{
                "fi":"Konvektiivinen lämmönsiirtokerroin",
                "en":"Convective Heat Coefficient"
            },
            "Create":{
                "fi":"Luo",
                "en":"Create"
            },
            "Choose":{
                "fi":"Valitse",
                "en":"Choose"
            },
            "Conditions":{
                "fi":"Olosuhteet",
                "en":"Conditions"
            },
            "Save":{
                "fi":"Tallenna",
                "en":"Save"
            },
            "Selected persons":{
                "fi":"Valitut henkilöt",
                "en":"Selected persons"
            },
            "Settings":{
                "fi":"Asetukset",
                "en":"Settings"
            },
            "Start":{
                "fi":"Aloita",
                "en":"Start"
            },
            "Stop":{
                "fi":"Pysäytä",
                "en":"Stop"
            },
            "Personal details":{
                "fi":"Henkilön tiedot",
                "en":"Personal details"
            },
            "Name":{
                "fi":"Nimi",
                "en":"Name"
            },
            "Id (arbitrary, must be unique, users use this id to login to the mobile app)":{
                "fi":"Tunnus (vapaavalintainen, pitää olla ainutlaatuinen, tämän avulla henkilö kirjautuu mobiilisovellukseen)",
                "en":"Id (arbitrary, must be unique, users use this id to login to the mobile app)"
            },
            "Height":{
              "fi":"Pituus",
              "en":"Height"  
            },
            "Weight":{
                "fi":"Paino",
                "en":"Weight"
            },
            "Gender":{
                "fi":"Sukupuoli",
                "en":"Gender"
            },
            "Man":{
                "fi":"Mies",
                "en":"Man"
            },
            "Woman":{
                "fi":"Nainen",
                "en":"Woman"
            },
            "Date of birth":{
                "fi":"Syntymävuosi",
                "en":"Date of birth"
            },
            "Fat":{
                "fi":"Rasvaprosentti",
                "en":"Fat"
            },
            "Resting Heart Rate":{
                "fi":"Leposyke",
                "en":"Resting Heart Rate"
            },
            "Maximum Heart Rate":{
                "fi":"Maksimisyke",
                "en":"Maximum Heart Rate"
            },
            "Maximum Oxygen Uptake":{
                "fi":"Maksimaalinen hapenottokyky",
                "en":"Maximum Oxygen Uptake"
            },
            "New Case":{
                "fi":"Uusi tapaus",
                "en":"New Case"
            },
            "Beats":{
                "fi":"Lyöntiä",
                "en":"Beats"
            },
            "Remove":{
                "fi":"Poista",
                "en":"Remove"
            },
            "Personal information":{
                "fi":"Henkilön tiedot",
                "en":"Personal information"
            },
            "Year of birth":{
                "fi":"Syntymävuosi",
                "en":"Year of birth"
            },
            "monitoring":{
                "fi":"tarkkaillaan",
                "en":"monitoring"
            },
            "Firefighter (Firefighter jacket and trousers, helmet, safety gloves and boots, mid-layer and underwear with long sleeves, socks)":{
                "en":"Firefighter (Firefighter jacket and trousers, helmet, safety gloves and boots, mid-layer and underwear with long sleeves, socks)",
                "fi":"Palovarustus (Palotakki ja -housut, kypärä, hanskat, saappaat, välikerros ja pitkät alusvaatteet sekä sukat)"
            },
            "Half naked (Firefighter trousers on)":{
                "en":"Half naked (Firefighter trousers on)",
                "fi":"Puolialasti (Palomiehen housut jalassa)"
            },
            "Typical indoor clothing (Panties, shirt, jeans, socks, shoes)":{
                "en":"Typical indoor clothing (Panties, shirt, jeans, socks, shoes)",
                "fi":"Normaali sisävaatetus (Paita, pitkät housut, alushousut, sukat ja kengät)"
            },
            "Warm indoor clothing (Panties, shirt, jeans, round neck sweater, thick knee socks, shoes)":{
                "en":"Warm indoor clothing (Panties, shirt, jeans, round neck sweater, thick knee socks, shoes)",
                "fi":"Lämmin sisävaatetus (Neule, farkut, aluspaita, alushousut, sukat ja kengät)"
            },
            "Very light clothing (shorts)":{
                "en":"Very light clothing (shorts)",
                "fi":"Hyvin kevyt vaatetus (shortsit)"
            },
            "Summer athlete (sleeveless shirt, shorts, shoes)":{
                "en":"Summer athlete (sleeveless shirt, shorts, shoes)",
                "fi":"Kilpakävelijän varustus (Hihaton paita, shortsit sekä kengät)"
            },
            "Light clothing (T shirt, shorts, socks, shoes)":{
                "en":"Light clothing (T shirt, shorts, socks, shoes)",
                "fi":"Kevyt vaatetus (T-paita, shortsit, sukat sekä kengät)"
            },
            "Light evaporative clothing (T shirt, shorts, socks, shoes)":{
                "en":"Light evaporative clothing (T shirt, shorts, socks, shoes)",
                "fi":"Kevyt erittäin hengittävä vaatetus (T-paita, shortsit, sukat sekä kengät)"
            },
            "Light underwear (Shirt and panties)":{
                "en":"Light underwear (Shirt and panties)",
                "fi":"Kevyet alusvaatteet (paita ja housut)"
            },
            "Warm underwear (Shirt and panties)":{
                "en":"Warm underwear (Shirt and panties)",
                "fi":"Lämpimät alusvaatteet (paita ja housut)"
            },
            "Soldier summer (Field suit, helmet, ballistic vest, kypärä, alusvaatteet, socks, boots)":{
                "en":"Soldier summer (Field suit, helmet, ballistic vest, kypärä, alusvaatteet, socks, boots)",
                "fi":"Sotilasvarustus kesä (Maastopuku, kypärä, suojaliivit, kypärä, alusvaatteet, sukat ja maastokengät)"
            },
            "Very warm night wear (Thick blanket or sleeping bag, nightclothes with long sleeves and bolts)":{
                "en":"Very warm night wear (Thick blanket or sleeping bag, nightclothes with long sleeves and bolts)",
                "fi":"Hyvin lämmin yövaatetus (Paksu peite/makuupussi, pitkät alusvaatteet)"
            },
            "Users log into the mobile app with this id":{
                "en":"Users log into the mobile app with this id",
                "fi":"Käyttäjät kirjautuu mobiilisovellukseen tällä tunnuksella"
            },
            "Skin blood flow":{
                "en":"Skin blood flow",
                "fi":"Ihon verenkierto"
            },
            "Blood vessel age":{
                "en":"Blood vessel age",
                "fi":"Verisuonten ikä"
            },
            "Maximum skin blood flow":{
                "en":"Maximum skin blood flow",
                "fi":"Ihon maksimisimaalinen verenkierto"
            },
            "Basal skin blood flow":{
                "en":"Basal skin blood flow",
                "fi":"Ihon normaali verenkierto"
            },
            "Minimum skin blood flow":{
                "en":"Minimum skin blood flow",
                "fi":"Ihon minimaalinen verenkierto"
            },
            "Coefficient for skinMax":{
                "en":"Coefficient for skinMax",
                "fi":"Kerroin skinMax-muuttujalle"
            },
            "Coefficient for skinMin":{
                "en":"Coefficient for skinMin",
                "fi":"Kerroin skinMin-muuttujalle"
            },
            "Sweating":{
                "en":"Sweating",
                "fi":"Hikoilu"
            },
            "Max whole body sweating rate":{
                "en":"Max whole body sweating rate",
                "fi":"Maksimaalinen hikoilu"
            },
            "Min whole body sweating rate":{
                "en":"Min whole body sweating rate",
                "fi":"Minimaalinen hikoilu"
            },
            "Sweating slope":{
                "en":"Sweating slope",
                "fi":"Hikoilun kulmakerroin"
            },
            "Delta sweating onset temperature":{
                "en":"Delta sweating onset temperature",
                "fi":"Hikoilun alkulämpötila"
            },
            "Core temperature":{
                "en":"Core temperature",
                "fi":"Syvälämpötila"
            },
            "Skin temperature":{
                "en":"Skin temperature",
                "fi":"Iholämpötila"
            },
            "Neutral core temperature":{
                "en":"Neutral core temperature",
                "fi":"Normaali syvälämpötila"
            },
            "Core temperature upper limit":{
                "en":"Core temperature upper limit",
                "fi":"Syvälämpötilan yläraja"
            },
            "Core temperature lower limit":{
                "en":"Core temperature lower limit",
                "fi":"Syvälämpötilan alaraja"
            },
            "Skin temperature upper limit":{
                "en":"Skin temperature upper limit",
                "fi":"Iholämpötilan yläraja"
            },
            "Skin temperature lower limit":{
                "en":"Skin temperature lower limit",
                "fi":"Iholämpötilan alaraja"
            },
            "Temperatures":{
                "en":"Temperatures",
                "fi":"Lämpötilat"
            }
            

        };

        this.variableNames = {
            "coreTemperature":{
                "fi":"Syvälämpötila",
                "en":"Core Temperature"
            },
            "heatStrainIndex":{
                "fi":"Lämpörasitusindeksi",
                "en":"Physiological Strain Index"
            },
            "heartrate":{
                "fi":"Sydämen syke",
                "en":"Heart Rate"
            },
            "totalSweating":{
                "fi":"Hikoilu",
                "en":"Sweating"
            }
        };
        this.minmaxs = {
            "coreTemperature":{
                max:41,
                min: 35,
                step: 0.5
            },
            "heartrate":{
                max:200,
                min: 35,
                step: 1
            },
            "sweating_litrePerHour":{
                max:0.696,
                min: 0.1,
                step: 0.05
            },
            "totalSweating":{
                max: 1000,
                min: 0
            },
            "heatStrainIndex":{
                max: 10.0,
                min: 0.0,
                step: 0.05
            }
        }
    }
}